import tw, { styled } from 'twin.macro';
import { BREAKPOINTS } from '../../constants';
import { IExpanderProps } from './menu';

export interface ULProps {
  children?: React.ReactNode;
}

export const Hamburger = styled.button(() => {
  const out = [
    tw`block text-center relative mb-4 lg:mb-0 lg:absolute`,
    {
      right: '3%',
      top: 80,
      '&:after': {
        content: "''",
        backgroundImage: 'url(/images/hamburger.gif)',
        backgroundSize: 'contain',
        width: 29,
        height: 29,
        display: 'block',
      },
      [`@media screen and (max-width: ${BREAKPOINTS.lg}px)`]: {
        top: 9,
        bottom: 'auto',
      },
      [`@media screen and (max-width: ${BREAKPOINTS.xs}px)`]: {
        bottom: -9,
      },
    },
  ];
  return out;
});

export const NavWrapper = styled.nav(() => {
  const out = [
    tw`block text-center relative`,
    {
      top: 20,
      zIndex: 20,
      [`@media screen and (max-width: ${BREAKPOINTS.lg}px)`]: [
        tw`w-full`,
        {
          top: 'auto',
        },
      ],
    },
  ];
  return out;
});

export const Nav = styled.ul(() => {
  const out = [
    tw`pl-0 flex flex-col items-start bg-white lg:bg-transparent lg:items-center lg:flex-row lg:justify-between lg:mx-auto`,
    {
      maxWidth: 600,
      [`@media screen and (max-width: ${BREAKPOINTS.lg}px)`]: {
        maxWidth: '100%',
      },
      '> li': {
        display: 'block',
        '> a': [
          tw`lg:text-gray lg:hover:text-bordeaux`,
          {
            '&.active': tw`text-white lg:text-bordeaux`,
          },
        ],
      },
    },
  ];
  return out;
});

export const SubNav = styled.ul({
    ...tw`static lg:absolute flex justify-start content-start flex-col bg-transparent lg:bg-bordeaux-seethru px-0 lg:px-4 pb-0 lg:pb-2 mt-0 lg:mt-1`,
    visibility: 'hidden',
    opacity: 0,
    height: 0,
    transition: 'all .2s ease-out',

    '>li': {
      '>a': tw`text-white bg-bordeaux-seethru lg:bg-transparent text-lg hover:text-gray-light whitespace-nowrap`,
    },
});

export const ThirdNav = styled.ul({
    ...tw`static  lg:absolute flex justify-start content-start flex-col bg-transparent lg:bg-bordeaux-seethru px-0 lg:px-4 pb-0 lg:pb-2 mt-0 lg:mt-1`,
    visibility: 'hidden',
    opacity: 0,
    height: 0,
    transition: 'all .2s ease-out',
    '>li': {
      '>a': tw`text-white bg-bordeaux-seethru lg:bg-transparent text-lg hover:text-gray-light whitespace-nowrap`,
    },
});
interface IShowOverlay {
  isShown: boolean;
}

export const NavOverlay = styled.div<IShowOverlay>(props => {
  const out = [
    tw`hidden w-full h-full absolute`,
    {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 10,
    },
  ];
  if (props.isShown) {
    out.push(tw`block`);
  }
  return out;
});

export const Expander = styled.button<IExpanderProps>(props => {
  const out = [
    tw`block bg-shadow_black absolute flex justify-center items-center lg:hidden`,
    {
      top: 0,
      width: 50,
      height: 50,
      right: 0,
      '&:before': [
        tw`border-solid block`,
        {
          content: "''",
          width: 0,
          height: 0,
          borderWidth: '16.6px 10px 0 10px',
          borderColor: '#fff transparent transparent transparent',
          transform: props.isActive ? 'rotate(0Deg)' : 'rotate(-90Deg)',
          transition: 'transform 0.4s linear',
        },
      ],
    },
  ];
  return out;
});

export const NavItem = styled.li({
  ...tw`w-full h-auto relative lg:static lg:h-full lg:w-auto lg:bg-transparent`,
  '&.with-grandkids': {
      '>a.active + ul': [
        tw`lg:py-4 lg:pl-0`,
        {
          transition: 'all .2s ease-in',
        },
      ],
      '&>ul': [
        tw`flex-col lg:flex-row pl-0 lg:pr-4 lg:pl-0 `,
        {
          width: 800,
          left: '50%',
          transform: 'translateX(-50%)',
          '>li': [
            tw`float-left flex-grow pl-0 lg:pl-4 text-left`,
            {
              width: 'calc(100% / 3 - 1rem)',
              '>a': tw`font-bold text-xl lg:text-white hover:text-gray-light`,
              ul: [
                tw`flex-col items-start relative bg-transparent pr-0 pl-0 lg:pl-2 lg:pt-1 lg:pb-0`,
                {
                  li: [
                    tw`pb-1`,
                    {
                      a: tw`text-white font-normal text-base whitespace-normal hover:text-gray-lighter`,
                    },
                  ],
                },
              ],

              [`@media screen and (max-width: ${BREAKPOINTS.lg}px)`]: [
                tw`w-full`,
                {
                  'a+button+ul': {
                    visibility: 'hidden',
                    opacity: 0,
                    height: 0,
                    transition: 'all .2s ease-in',
                  },
                  '.active + button + ul': {
                    visibility: 'visible',
                    opacity: 1,
                    height: 'auto',
                  },
                },
                // {
                //   height: 50,
                // },
              ],
            },
          ],
          [`@media screen and (max-width: ${BREAKPOINTS.lg}px)`]: {
            transform: 'none',
            width: '100%',
            left: 'auto',
          },
        },
      ],
    },
  a: [
    tw`pl-4 lg:pl-0 bg-bordeaux block lg:bg-transparent text-white text-xl font-primary font-bold whitespace-nowrap`,
    {
      '&.active': [
        tw`text-gray lg:text-white`,
        {
          '&+ul': tw`bg-bordeaux-seethru px-0 py-0 lg:py-4 lg:px-4 lg:text-white`,
          '&+button+ul > li >ul': {
            li: {
              height: 50,
            },
          },
          '&+button+ul, &+button+ul > li >ul, &+ul, &+ul > li >ul': [
            tw`text-left `,
            {
              visibility: 'visible',
              opacity: 1,
              height: 'auto',
              a: tw`font-normal`,
            },
          ],
        },
      ],
      [`@media screen and (max-width: ${BREAKPOINTS.lg}px)`]: [
        tw`flex items-center hover:text-white`,
        {
          height: 50,
          '&.active': [
            tw`text-white`,
            {
              '&+button +ul > li >ul': {
                visibility: 'hidden',
                opacity: 0,
                height: 0,
              },
            },
          ],
        },
      ],
    },
  ],
});

export const Link = styled.a(() => tw`block text-left hover:no-underline hover:text-bordeaux`);
export const Button =  tw`text-gray text-2xl font-primary`;
